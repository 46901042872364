.img-xont {
  /* width:200px; */
  height: 180px;
}
.img-xont img {
  object-fit: cover;
  width: 1143px;
  height: 180px;
  background-color: red;
}
.btn-cont-primary {
  margin: 0 7px;
  cursor: pointer;
  font-size: 36px;
  margin-top: -58px;
  color: white;
}
.slider-btn {
  display: flex;
  justify-content: center;
}
.selected-dot {
  margin: 0 7px;
  cursor: pointer;
  font-size: 36px;
  margin-top: -58px;
  color: #605d5d;
}
@media screen and (max-width: 786px) {
  .img-xont img {
    width: 483px;
  }
}
@media screen and (max-width: 475px) {
  .img-xont img {
    width: 370px;
  }
}