.side-bar {
  background-color: #00162d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  margin-top: -30px;
  border-right: 2px solid black;
  /* padding: 10px; */
}
.sidebar-cont {
  border-right: 1px solid black;
  height: 100vh;
}

.sidebar-cont h6 {
  background-color: #7bc3df;
  font-size: 18px;
 
  color: white;
 text-align: left;
  padding: 4px;
 padding-left: 20px;
 
}

.side-bar p {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.side-bar p:hover {
  width: 80%;
  background-color: #137d79;
  color: white;
}
