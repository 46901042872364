.fancy-bet h2{
    display: flex;
    text-transform: uppercase;
    align-items: center;
    background-color: #7bc3df;
    color: white;
    height: 30px;
    margin-top: 0px;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 600;
}

.fancy-bet td{
    font-weight: 600;
}

.fancy-bet td:nth-child(1) {
    width: 66%;
    
    
  }
  .fancy-bet td:nth-child(2),
  .fancy-bet th:nth-child(2)
  {
    font-family: "Poppins", sans-serif;
    width: 17%;
    text-align: center;
    font-weight: 600;
    background-color: pink;
}
.fancy-bet td:nth-child(3),
.fancy-bet th:nth-child(3) {
  font-family: "Poppins", sans-serif;
   text-align: center;
   background-color: #98d4ff;
  
}

