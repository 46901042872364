html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-data {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.side-Bar {
  /* margin-top: 33px; */
  text-align: center;
  width: 15%;
  overflow-y: auto;
  height: 100%;
}

.side-Bar::-webkit-scrollbar {
  width: 5px;
  background-color: black;
}

.routing-data {
  /* margin-top: -10px; */
  flex: 1;
  overflow-y: auto;
}

@media screen and (max-width: 786px) {
  .side-Bar {
    display: none;
  }
}
