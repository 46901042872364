.bethistory-statement-container h2 {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #7bc3df;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.bethistory-options-container {
  display: flex;
  gap: 30px;
}
.report-type-dropdown select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
}
.report-type-dropdown select:hover {
  border-color: #888;
}

input[type="date"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.btn-act {
  display: flex;
  gap: 10px;
}

.btn-act button {
  padding: 8px 12px;
  background-color: #247869;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80px;
  height: 40px;
}

.btn-act button:hover {
  background-color: #45a049;
}

.balance-container {
  display: flex;
  justify-content: space-between;
}
.profit-search input {
  width: 150px;
  margin: 20px 0px -20px 10px;
}
.profit-loss-table th {
  color: white;
  background-color: #3b8aa3;
}

@media screen and (max-width: 768px) {
  .bethistory-options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .bethistory-options-container input[type="date"] {
    width: 80%;
    margin-left: 30px;
  }
  .bethistory-options-container .report-type-dropdown select {
    width: 80%;
    margin-left: 30px;
  }

  .profit-loss-table {
    overflow-y: scroll;
  }
  .profit-loss-table td {
    font-size: 10px !important;
  }
}
