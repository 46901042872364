/* .password-form-data h2 {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #001f3f;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.frm-cntrl label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  margin-left: 10px;
}

.frm-cntrl input {
  width: 30%;
  padding: 13px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-left: 10px;
  border-radius: 10px;
}
.frm-cntrl button {
  background: linear-gradient(to right, #007bff, #00bfff);
  color: #fff;
  padding: 15px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
}

.frm-cntrl button:hover {
  background-color: #0056b3;
} */
/* passwordManager.css */
.password-change h2 {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #001f3f;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.password-form-data {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.frm-cntrl {
  display: flex;
  flex-direction: column;
}

.frm-cntrl label {
  margin-bottom: 5px;
}

.frm-cntrl input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.frm-cntrl button {
  width: 100%;
  background: linear-gradient(to top, #1a4360, #1aa3b1);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.frm-cntrl button:hover {
  background: linear-gradient(to top, #1aa3b1, #1a4360);
}
