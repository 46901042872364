/* .login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(#022c43, #18b0c8);
}

.login-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #18b0c8;
  background-image: linear-gradient(#146578, #7dcee2);
}

.login {
  top: 50%;
  width: 300px;
  padding: 30px;
  margin: 50% 15px;
  border-radius: 5px;
  background: white;
}
.text-centers {
  text-align: center !important;
}
.login h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
  color: #312f3d;
}
.form-group {
  margin-bottom: 1rem;
}

.loaderDivCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.loaderParentDiv {
  width: "100%";
  height: "100%";
  z-index: "999";
  overflow: "scroll";
} */

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(#022c43, #18b0c8);
}

.login-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #18b0c8;
  background-image: linear-gradient(#146578, #7dcee2);
}

.login {
  width: 480px;
  padding: 30px;
  border-radius: 10px;
  background: white;
  border: 6px solid #18b0c8;
}

.text-centers {
  text-align: center !important;
}

.login h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #312f3d;
}
.login input {
  padding: 15px;
  height: 45px;
  border: 1px solid black;
  border-radius: 13px;
}
.login button {
  background: linear-gradient(to top, #1a4360, #1aa3b1);
}
.login button:hover {
  background: linear-gradient(to top, #1aa3b1, #1a4360);
}
.form-group {
  margin-bottom: 1rem;
}
.close-icon {
  position: absolute;
  left: 960px;
  top: 270px;
}

.loaderDivCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderParentDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
