.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to top, #4b97cf, #003136);
  height: 80px;
}

.logo {
  font-size: 1.5rem;
  color: white;
  margin-left: 50px;
}
.main-amount {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

p {
  display: inline-block;
  cursor: pointer;
}

p span {
  display: block;
  margin: 0;
}

p span:first-child {
  font-size: 37px;
  position: relative;
  top: 10px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

p span:last-child {
  font-weight: normal;
  font-size: 24px;
  margin-left: 27px;
}

.main-amount button {
  flex: 1;
  width: 100px;
  background-color: #25a2b5;
  color: white;
  padding: 3px;
  border: 1px solid #bfbaba;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
  margin-right: 25px;
  font-weight: 700;
}
.btn1 button {
  height: 10x;
}
.actn-holder-details h3 {
  margin-left: 13px;
  font-size: medium;
}

.user-need-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #045662;
  width: 19%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  transition: transform 6s ease-in-out, opacity 6s ease-in-out;
}
.login-button {
  width: 100px;
  height: 50px;
  background-color: #36bdd3;
  border: none;
  margin-right: 40px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-family: math;
  font-size: 20px;
}
.btn-grp {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.btn-grp button {
  background-color: #36bdd3;
  color: #ffffff;
  font-weight: 600;
  font-family: system-ui;
  border: 1px solid snow;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 110px;
  height: 50px;
}

.btn-grp button:hover {
  background-color: #0056b3;
}

.user-details-settings {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  color: white;
}

.user-details-settings button {
  border: none;
  color: white;
  font-size: 16px;
  font-family: math;
  background-color: #045662;
  padding: 5px 0px 5px 19px;
  margin-top: 10px;

  width: 100%;
  text-align: left;
}

.user-details-settings button:hover {
  background-color: rgb(7, 176, 176);
  cursor: pointer;
}
.close-menu {
  text-align: right;
  color: white;
  font-size: medium;
}
.closed-toogle {
  font-size: 33px !important;
}
.actn-holder-details {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .logo {
    margin: 30px;
    margin-top: 40px;
  }
  .main-amount {
    margin: 10px;
  }
  .main-amount button {
    margin-right: 0px;
  }
  .user-need-data {
    width: 42%;
    z-index: 1;
  }

  .actn-holder-details h3 {
    margin-left: 5px;
  }
  .closed-toogle {
    font-size: 20px !important;
  }
  .user-details-settings button {
    padding: 3px;
    padding-left: 17px;
  }
  .btn-grp {
    margin-left: 10px;
  }
  .btn-grp button {
    width: 100px;
    padding: 0px;
  }
}
@media screen and (max-width: 475px) {
  .logo {
    margin-left: 15px;
  }
  .login-button {
    width: 80px;
    margin-right: 15px;
  }
  .user-need-data {
    width: 62%;
  }
  .btn-grp button {
    padding: 0px;
  }
}
