/* .bet-home {
  margin-top: -2px;
}
.game-data-container {
  display: flex;
  justify-content: space-around;
  border: 1px solid bisque;
  height: 55px;
}
.bet-home h6 {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #001f3f;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.betting-tit {
  background-color: aqua;
  height: 30px;
  align-content: center;
}
.game-odd {
  background-color: blue;
  height: 30px;
  width: 50px;
  text-align: center;
}
.game-even {
  height: 30px;
  width: 50px;
  text-align: center;
  background-color: pink;
}

 */
.match-list-container p {
  display: flex;
  text-transform: uppercase;
  align-items: center;

  background-color: #7bc3df;
  color: white;
  height: 30px;
  margin-top: 0px;
}
.match-data-stakes td {
  padding: 3px;
  font-weight: 600;
}
.match-data-stakes td:nth-child(1) {
  width: 55%;
}
.match-data-stakes td:nth-child(2),
.match-data-stakes td:nth-child(4),
.match-data-stakes td:nth-child(6) {
  font-family: "Poppins", sans-serif;
  text-align: center;
  background-color: #98d4ff;
}

.match-data-stakes td:nth-child(3),
.match-data-stakes td:nth-child(5),
.match-data-stakes td:nth-child(7) {
  font-family: "Poppins", sans-serif;

  text-align: center;
  background-color: pink;
}
.match-data-stakes td:nth-child(1) {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.team2-name {
  margin-left: 8px;
}
.team1 {
  margin-left: 5px;
}
.match-time {
  color: #4f4f84;
  font-weight: bold;
}
.pinned-icon {
  width: 20px;
  color: #1bb1c1e0;
}
.pinned-icon:hover {
  color: white;
  background-color: #1bb1c1e0;
}
@media screen and (max-width: 475px) {
  .match-data-stakes td:nth-child(1) {
    font-size: 9px;
    font-weight: 300;
  }
  .match-list-container p {
    padding-left: 10px;
  }
}
