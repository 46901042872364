.cricket-bets-container {
  max-width: 800px;
  margin: auto;
}

.match-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.match-container h3 {
  color: #3498db;
}

.bet-options {
  margin-top: 10px;
}

.bet-option {
  margin-top: 8px;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
}

.bet-option p {
  margin: 5px 0;
}

/* Add more styles as needed */
