.account-statement-container h2 {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #001f3f;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.report-options-container {
  display: flex;
  gap: 40px;
}
.report-type-dropdown select {
  margin-left: 10px;
  height: 38px;
  width: 264p;
  font-size: 8px;
  border: 1px solid gainsboro;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;

  cursor: pointer;
}
.report-type-dropdown select:hover {
  border-color: #888;
}
.pagination-number button {
  background-color: #3b8aa3;
}

input[type="date"] {
  margin-left: 10px;
  height: 38px;
  width: 264p;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.btn-act {
  display: flex;
  gap: 10px;
}
.div nav ul {
  margin-left: 850px;
}

.btn-act button {
  padding: 3px 12px;
  background: linear-gradient(to top, #1a4360, #1aa3b1);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80px;
  height: 35px;
  font-size: 14px;
}

.btn-act button:hover {
  background: linear-gradient(to top, #1aa3b1, #1a4360);
}

.account-statement-table th {
  background-color: #3b8aa3;
  color: white;
  font-family: emoji;
}

.account-statement-table table {
  margin-top: 30px;
}
.even-row {
  background-color:#dee8ef;
}

.items-per-page {
display: flex;
justify-content: flex-end;
margin-bottom: -30px;
margin-right: 5px;
}
.items-per-page select{
  margin: 5px;
}


@media screen and (max-width: 768px) {
  .report-options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .report-options-container input[type="date"] {
    width: 80%;
    margin-left: 30px;
  }
  .report-options-container .report-type-dropdown select {
    width: 80%;
    margin-left: 30px;
  }
  .btn-act {
    margin-left: 30px;
  }
  .account-statement-table {
    overflow-x: scroll;
    
  }
  .account-statement-tablesec::-webkit-scrollbar {
    height: 5px;
  }

  .account-statement-table::-webkit-scrollbar-thumb {
    background-color: black;
     border-radius: 15px;
  }
  .account-statement-table td {
    font-size: 12px;
  }
}
@media screen and (max-width: 475px) {
  .account-statement-table td,
  th {
    font-size: 10px;
  }
}
