.navigation-sec {
  display: flex;
  height: 40px;
  background-color: hsl(188, 92%, 20%);
}

.navigation-sec a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90px;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: small;
}

.navigation-sec a:hover,
.navigation-sec a.active {
  background-color: #0ba1a1;
}

@keyframes colorEffect {
  0%,
  100% {
    color: initial;
  }

  33.33% {
    color: red;
  }

  66.66% {
    color: yellow;
  }

  100% {
    color: blue;
  }
}

.special-link {
  animation: colorEffect 0.7s infinite;
}

.marquiee {
  background-color: #00000096;
  color: whitesmoke;

}

@media (max-width: 768px) {
  .navigation-sec a:hover ,
  .navigation-sec a.active{
    background-color:transparent;
  }
  .navigation-sec a {
    padding: 0 40px;
  }

  .navigation-sec {
    overflow-x: scroll;
  }

  .navigation-sec::-webkit-scrollbar {
    height: 5px;
  }

  .navigation-sec::-webkit-scrollbar-thumb {
    background-color: black;

    border-radius: 15px;
  }
}
