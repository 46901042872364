
.data {
  display: flex;
}
.sidebar-item {
  margin-bottom: 0px;
  border-radius: 4px;
  cursor: pointer;
  font-size: smaller;
  font-weight: bold;
  border-bottom: 1px solid beige;
}
.sidebar-item p {
  
  color: #061b68;
  width: 100%;
  font-size: 14px;
  padding-left: 7px;
  padding-top: 11px;

}
.sidebar-item p:hover{
  color: white;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-title:hover {
  color: white;
  background-color: #7bc3df;
  border-radius: 4px;
}

.bi-caret-down-fill {
  margin-right: 14px;
  font-size: 16px;
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.sidebar-item.open .bi-caret-down-fill {
  transform: rotate(180deg);
}

.sidebar-content {
  background-color: #f0f0f0;
  margin-left: 0px;
  padding-left: 30px;
}
.subchild p {
  margin: 0px;
  padding: 0px;
  color: black;
}
.child :hover,
.subchild :hover {
  color: black;
  background-color: #f0f0f0;
}


