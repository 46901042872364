.match-odd {
  display: flex;
  justify-content: space-between;
  background-color: #001f3f;
  color: white;
  padding-top: 10px;
}
.match-odd p {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.fancy-bets p {
  line-height: normal;
  display: flex;
  padding-left: 10px;

  align-items: center;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #001f3f;
  color: white;
  height: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}
th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  cursor: pointer;
}

button {
  cursor: pointer;
}
.team-bet-container td:nth-child(1) {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.team-bet-container th:nth-child(2),
.team-bet-container td:nth-child(2) {
  font-family: "Poppins", sans-serif;
  width: 17%;
  text-align: center;
  font-weight: 600;

  background-color: #98d4ff;
}
.team-bet-container th:nth-child(3),
.team-bet-container td:nth-child(3) {
  font-family: "Poppins", sans-serif;
  width: 17%;
  text-align: center;
  background-color: pink;
  font-weight: 600;
}
.bet-amount {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.fancy-bet-container table {
  font-size: 15px ;
  font-weight: 600;
  
}
.fancy-bet-container th:nth-child(2),
.fancy-bet-container td:nth-child(2) {
  font-family: "Poppins", sans-serif;
  width: 17%;
  text-align: center;
  background-color: pink;
}
.fancy-bet-container th:nth-child(3),
.fancy-bet-container td:nth-child(3) {
  font-family: "Poppins", sans-serif;
  width: 17%;
  text-align: center;
  background-color: #98d4ff;
}

/*  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Bet PLACED CLASS CONTAINER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.bet-placed-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}

.bet-placed-container p {
  font-size: 18px;
  margin-bottom: 2px;
}

.bet-amount-fields {
  display: flex;
  gap: 10px;
  align-items: center;
}

.bet-amount-fields button,
.bet-amount-fields input {
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
}

.bet-amount-fields button {
  background-color: #f0f0f0;
  cursor: pointer;
}

.bet-amount-fields input {
  width: 100px;
  text-align: center;
}

.bet-amount button {
  background-color: #7bc3df;
  width:60px;
  height: 35px;
  border: none;
  border-radius: 3px;
 
  cursor: pointer;
  text-transform: capitalize;
  


}

.bet-amount button.close,
.bet-amount button.placed
 {
  width: 100px;
  color: white;
  background-color: #e74c3c;
}

.bet-amount button.placed {
  background-color: #2ecc71;
}

.increment-btn,
.decrement-btn {
  font-size: larger;
  height: 30px;
  width: 30px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}
.all-fancy {
  margin-top: -15px;
  display: flex;
  background-color: #7bc3df;
  justify-content: space-between;
}
.all-fancy button{
  border: none ;
  color:white;
  background-color: transparent;
  padding: 4px 10px;


}
.all-fancy button:hover{
  background-color: hsl(188, 92%, 20%)
}
@media screen and (max-width: 768px) {
  .hello {
    display: none;
  }
  .bet-placed-container {
    justify-content: flex-start;
    gap: 5px;
    
  }
  .bet-amount-fields {

    gap: 2px;
    
  }
 
  .bet-placed-container p{
    display: none;
  }
 
  .bet-amount-fields button
  {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: larger;
    height: 33px;
    width: 67px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    padding: 8px 28px;
    margin: 0px;
 
  }
   .bet-amount-fields input{
    padding: 15px;
    margin: 0px;
    width: 100px;
   }
 .increment-btn{
  padding: 0px;
  margin: 0px;
 }
}
