.cgaccount-statement-container h2 {
  line-height: normal;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #7bc3df;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.cgreport-options-container {
  display: flex;
  gap: 30px;
}
.report-type-dropdown select {
  margin-left: 10px;
  height: 38px;
  width: 264px;
  font-size: 12px;
  border: 1px solid gray;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
}
.report-type-dropdown select:hover {
  border-color: #888;
}

.btn-act {
  display: flex;
  gap: 10px;
}

.balance-container {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .cgreport-options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .cgreport-options-container input[type="date"] {
    width: 80%;
    margin-left: 30px;
  }
  .cgreport-options-container .report-type-dropdown select {
    width: 80%;
    margin-left: 30px;
  }
}
